/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.cta figure').matchHeight();
        $('.tiles .tile header').matchHeight();
        $('.tiles .tile header h2').matchHeight();
        $('.footer-ctas .strapline').matchHeight();

        /****************
        **  MAIN NAV hover/toggle styles
        *****************/
        $('#navPrimary').on('show.bs.collapse', function () {
          $('button.navbar-toggler.ready').removeClass('ready');
          $("header.banner").addClass('expanded');
        });
        $('#navPrimary').on('hide.bs.collapse', function () {
          $("header.banner").removeClass('expanded');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // CONTACT
    'contact': {
      init: function() {
        $('#formselect').change(function () {
          if ($( "#formselect" ).val() === "contact") {
            $('.contacter').removeClass('hidden');
            $('.complainer').addClass('hidden');
            $('.sponsorshiper').addClass('hidden');
            $('.wholesaler').addClass('hidden');
          }
          if ($( "#formselect" ).val() === "complaint") {
            $('.complainer').removeClass('hidden');
            $('.contacter').addClass('hidden');
            $('.sponsorshiper').addClass('hidden');
            $('.wholesaler').addClass('hidden');
          }
          if ($( "#formselect" ).val() === "sponsorship") {
            $('.sponsorshiper').removeClass('hidden');
            $('.complainer').addClass('hidden');
            $('.contacter').addClass('hidden');
            $('.wholesaler').addClass('hidden');
          }
          if ($( "#formselect" ).val() === "wholesale") {
            $('.wholesaler').removeClass('hidden');
            $('.complainer').addClass('hidden');
            $('.contacter').addClass('hidden');
            $('.sponsorshiper').addClass('hidden');
          }
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'rentals': {
      init: function() {
        $('.rental-tabs ul.nav li a').click(function() {
          var tabLocation = $(this).attr('href').replace('#','');
          $('.rental-tabs ul.nav li').removeClass('current');
          $(this).removeClass('current');
          $(this).parent('li').addClass('current');

          $('.rental-tabs .tab-content .tab-pane').removeClass('current');
          $('.rental-tabs .tab-content .tab-pane#'+tabLocation).addClass('current');

        });
        $('.rental-tabs ul.nav li a').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single Event
    'single_tribe_events': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.tribe_events .tribe-block__event-price').detach().appendTo( $('.event-sidebar .event-tickets') );
        $('.tribe_events .tribe-block__event-website').detach().appendTo( $('.event-sidebar .event-website') );
        $('.tribe_events .tribe-block__related-events__title').detach().appendTo( $('.tribe-events-related-location') );
        $('.tribe_events ul.tribe-related-events').detach().appendTo( $('.tribe-events-related-location') );

        $('.tribe_events .tribe-block__venue').hide();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Blog
    'blog': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.hentry figure').matchHeight();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
     // BEERS page
    'beers': {
      init: function() {
        // JavaScript to be fired on the home page


        $('.beerslide').slick({
          dots: false,
          arrows: true,
          infinite: true,
          speed: 1000,
          autoplay: false,
          autoplaySpeed: 4000,
          rows: 0,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single Beer
    'single_beer': {
      init: function() {
        // JavaScript to be fired on the home page
        $(".beer-bar .beer-searcher h3").html("Find it now!");
/*
        var beerTitle = $('.beer-bar .strapline').attr('title');
        if (beerTitle) {
          $(".beer-bar .strapline").text(function(index, text) {
          	return text.replace('Coney Island Beer', beerTitle);
      		});
        }
*/

        $( ".footer-ctas form.beerfinder-simple input[name=brand]" ).remove();

        $('.slideshow-wrap').slick({
          dots: false,
          arrows: false,
          infinite: true,
          speed: 1000,
          autoplay: false,
          autoplaySpeed: 4000,
          fade: true,
          pauseOnHover: false,
          rows: 0,
          cssEase: 'linear'
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
